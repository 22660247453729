enum RequestStatus {
  new = 'NEW',
  sent = 'FILED',
  outdated = 'EXPIRED',
}

type ConfigValueType = {
  name: string;
  backgroundColor: string;
  color: string;
  value: RequestStatus;
};

const config: Record<RequestStatus, ConfigValueType> = {
  [RequestStatus.new]: {
    value: RequestStatus.new,
    name: 'Новая',
    backgroundColor: '#545CD8',
    color: '#fff',
  },
  [RequestStatus.sent]: {
    value: RequestStatus.sent,
    name: 'Подана',
    backgroundColor: 'var(--success-color)',
    color: '#fff',
  },
  [RequestStatus.outdated]: {
    value: RequestStatus.outdated,
    name: 'Просрочена',
    backgroundColor: 'var(--warning-color)',
    color: '#fff',
  },
};

export { config, RequestStatus };
