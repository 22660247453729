import { useRef } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import styles from './Sidebar.module.scss';

import { RootState, useAppDispatch } from '@/store';
import { setEnableMobileMenu } from '@/store/layoutSlice';
import { AppMenu } from '@/components/app';
import { Paths } from '@/routes';
import {
  ClassesIcon,
  CogsIcon,
  ListIcon,
  OrdersIcon,
  PaidNutritionIcon,
  ReportsIcon,
  SchoolIcon,
  SubscribersBaseIcon,
  SubscribersBaseLightIcon,
  TeacherIcon,
  TransactionsIcon,
} from '@/components/icons';
import { AppFlavor } from '@/flavors';

function Sidebar() {
  //
  // Refs
  //

  const transitionNavRef = useRef(null);
  const backgroundSidebarRef = useRef(null);
  const { enablePaidNutrition } = AppFlavor;

  //
  // Store
  //

  const dispatch = useAppDispatch();
  const { schools } = useSelector((state: RootState) => state.schools);
  const { user } = useSelector((state: RootState) => state.profile);

  //
  // Computed
  //

  const getMenuItems = () => {
    const items = [
      {
        icon: <SchoolIcon />,
        title: 'Школы',
        to: schools.length > 0 ? Paths.allSchools : Paths.newSchool,
      },
      {
        icon: <ClassesIcon />,
        title: 'Классы',
        to: Paths.classes,
      },
      {
        icon: <TeacherIcon />,
        title: 'Список учителей',
        to: Paths.teachers,
      },
      {
        icon: <ListIcon />,
        title: 'Заявки',
        to: Paths.requests,
      },
      {
        icon: <TransactionsIcon />,
        title: 'Транзакции',
        to: Paths.transactions,
      },
      {
        icon: <OrdersIcon />,
        title: 'Заказы',
        to: Paths.orders,
      },
      ...(enablePaidNutrition ? [{
        icon: <PaidNutritionIcon />,
        title: 'Комплексное питание',
        to: '/paid-nutrition',
      }, {
        icon: <ReportsIcon />,
        title: 'Отчеты',
        to: '/reports',
      }] : [{
        icon: <ReportsIcon />,
        title: 'Отчеты',
        to: '/reports',
      }]),
      {
        icon: <SubscribersBaseIcon />,
        title: 'База подписчиков',
        items: [
          {
            title: 'Родители',
            to: Paths.subsParents,
            icon: <SubscribersBaseLightIcon />,
          },
          {
            title: 'Ученики',
            to: Paths.subsStudents,
            icon: <SubscribersBaseLightIcon />,
          },
          {
            title: 'Учителя',
            to: Paths.subsTeachers,
            icon: <SubscribersBaseLightIcon />,
          },
        ],
      },
    ];

    if (user?.isSeniorEmployee) {
      items.push({
        icon: <CogsIcon />,
        title: 'Настройки',
        to: '/settings/main',
      });
    }

    return items;
  };

  //
  // Render
  //

  const renderMenuItems = (
    <AppMenu className="vertical-nav-menu" classNameStateIcon="pe-7s-angle-down" items={getMenuItems()} />
  );

  return (
    <>
      <div className="sidebar-mobile-overlay" onClick={() => dispatch(setEnableMobileMenu(false))} />
      <TransitionGroup appear className={cn('app-sidebar sidebar-shadow')} enter={false} exit={false}>
        <CSSTransition classNames="sidebar-animation" key={1} nodeRef={transitionNavRef} timeout={500}>
          <PerfectScrollbar className={styles.outer}>
            <div className={cn('app-sidebar__inner', styles.inner)} ref={transitionNavRef}>
              {renderMenuItems}
            </div>
          </PerfectScrollbar>
        </CSSTransition>
        <CSSTransition classNames="sidebar-animation" key={2} nodeRef={backgroundSidebarRef} timeout={500}>
          <div className={cn('app-sidebar-bg')} ref={backgroundSidebarRef} />
        </CSSTransition>
      </TransitionGroup>
    </>
  );
}

export default Sidebar;
