import { useEffect, useMemo, useState } from 'react';
import { Badge, Tooltip } from 'reactstrap';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import styles from './SchoolsTable.module.scss';

import { SchoolIikoSettings } from '@/models/entity';
import { AppInputField, AppLoader, AppSearch, AppTable } from '@/components/app';
import { useTableUrlParams } from '@/hooks';
import { formatDateToDateAndTime } from '@/utils/date';
import { QuestionMarkIcon } from '@/components/icons';
import { notify } from '@/utils/notifications';
import { SettingsService } from '@/services';
import { RootState } from '@/store';
import AppDropdownCheckboxSelect from '@/components/app/AppDropdownCheckboxSelect';

export default function SchoolsTable() {
  const { search: urlSearch, currentPage: urlCurrentPage, itemsPerPage: urlItemsPerPage } = useTableUrlParams();

  //
  // Store
  //

  const { schools: storeSchools } = useSelector((state: RootState) => state.schools);

  //
  // State
  //

  const [loading, setLoading] = useState<boolean>(false);
  const [schools, setSchools] = useState<SchoolIikoSettings[]>([]);
  const [search, setSearch] = useState<string>(urlSearch || '');
  const [currentPage, setCurrentPage] = useState<number>(urlCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState<number>(urlItemsPerPage);
  const [questionMarkTooltipOpened, setQuestionMarkTooltipOpened] = useState<boolean>(false);
  const [schoolsFilter, setSchoolsFilter] = useState<string[]>([]);
  const [connectionFilter, setConnectionFilter] = useState<boolean | null>(null);

  //
  // Computed
  //

  const filteredSchools = useMemo(
    () =>
      schools
        .filter(
          (st) =>
            st.name.toLowerCase().includes(search.toLowerCase()) ||
            st.plugins.some((p) => p.auth_token.toLowerCase().includes(search.toLowerCase())),
        )
        .filter((st) => (schoolsFilter.length === 0 ? true : schoolsFilter.includes(st.id)))
        .filter((st) => (connectionFilter == null ? true : st.plugins.some((p) => p.is_active === connectionFilter)))
        .sort((a, b) => {
          const aPlugins = a.plugins.sort(
            (x, y) => new Date(x.date_joined).getTime() - new Date(y.date_joined).getTime(),
          );
          const bPlugins = b.plugins.sort(
            (x, y) => new Date(x.date_joined).getTime() - new Date(y.date_joined).getTime(),
          );
          const aRecentPlugin = aPlugins.length === 0 ? null : aPlugins[0];
          const bRecentPlugin = bPlugins.length === 0 ? null : bPlugins[0];

          return (
            new Date(aRecentPlugin?.date_joined || new Date()).getTime() -
            new Date(bRecentPlugin?.date_joined || new Date()).getTime()
          );
        }),
    [schools, search, schoolsFilter, connectionFilter],
  );
  const paginatedSchools = useMemo(() => {
    const currentPageDivider = currentPage <= 0 ? 0 : currentPage - 1;
    const startIndex = currentPageDivider * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return filteredSchools.slice(startIndex, endIndex);
  }, [currentPage, filteredSchools, itemsPerPage]);
  const totalPages = useMemo(() => Math.ceil(filteredSchools.length / itemsPerPage), [itemsPerPage, filteredSchools]);

  //
  // Methods
  //

  const loadSchoolsSettings = async () => {
    setLoading(true);

    try {
      const { schoolIikoSettings } = await SettingsService.getSchoolsIikoSettings({
        currentPage,
        itemsPerPage,
        search,
      });
      setSchools(schoolIikoSettings);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  //
  // Effects
  //

  useEffect(() => {
    loadSchoolsSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  // Render
  //

  return (
    <section className={styles.host}>
      <section className={styles.actions}>
        <section className={styles.filters}>
          <AppInputField
            className={cn(styles.filter, styles.multiSelectFilter, styles.schoolsFilter)}
            controlType="multi-select"
            onChange={(value) => setSchoolsFilter(value)}
            options={storeSchools.map((s) => ({ label: s.name, value: s.id }))}
            placeholder="Выберите школу"
            value={schoolsFilter}
          />

          <AppDropdownCheckboxSelect
            className={cn(styles.filter, styles.multiSelectFilter, styles.connectionFilter)}
            onChange={(option) => (option == null ? setConnectionFilter(null) : setConnectionFilter(option.value))}
            options={[
              { label: 'Подключено', value: true },
              { label: 'Не подключено', value: false },
            ]}
            placeholder="Подключение плагина"
            value={
              connectionFilter != null
                ? {
                  label: connectionFilter ? 'Подключено' : 'Не подключено',
                  value: connectionFilter,
                }
                : null
            }
          />
        </section>

        <AppSearch
          onChange={(value) => {
            setSearch(value);
          }}
          onClickRefreshButton={loadSchoolsSettings}
          value={search}
        />
      </section>

      <AppLoader active={loading} />
      <AppTable
        className={styles.table}
        columns={[
          {
            value: (school) => <div style={{ wordBreak: 'break-word' }}>{school.name}</div>,
            className: 'text-left',
            header: {
              title: 'Название школы',
              className: 'text-left',
            },
          },
          {
            value: (school) => (
              <div className={styles.multiRowCell}>
                {school.plugins.map((plugin) => (
                  <span
                    className={styles.tokenCell}
                    key={plugin.auth_token}
                    onClick={() => {
                      try {
                        navigator.clipboard.writeText(plugin.auth_token);
                        notify(`Токен ${plugin.auth_token} скопирован в буфер обмена`, 'success');
                      } catch (error) {}
                    }}
                    style={{ wordBreak: 'break-word' }}
                  >
                    <span>{plugin.auth_token}</span>

                    <svg
                      fill="none"
                      height="15"
                      onClick={(e) => {
                        e.stopPropagation();
                        try {
                          navigator.clipboard.writeText(plugin.auth_token);
                          notify(`Токен ${plugin.auth_token} скопирован в буфер обмена`, 'success');
                        } catch (error) {}
                      }}
                      viewBox="0 0 14 15"
                      width="14"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.33335 1.08301H2.33335C1.69169 1.08301 1.16669 1.60801 1.16669 2.24967V10.4163H2.33335V2.24967H9.33335V1.08301ZM11.0834 3.41634H4.66669C4.02502 3.41634 3.50002 3.94134 3.50002 4.58301V12.7497C3.50002 13.3913 4.02502 13.9163 4.66669 13.9163H11.0834C11.725 13.9163 12.25 13.3913 12.25 12.7497V4.58301C12.25 3.94134 11.725 3.41634 11.0834 3.41634ZM11.0834 12.7497H4.66669V4.58301H11.0834V12.7497Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                ))}
              </div>
            ),
            className: 'text-left',
            header: {
              title: (
                <div className={styles.tokenHeaderCell}>
                  <span>Токен плагина</span>{' '}
                  <>
                    <QuestionMarkIcon id="questionMark" />
                    <Tooltip
                      className="tooltip-light"
                      isOpen={questionMarkTooltipOpened}
                      placement={'top'}
                      target="questionMark"
                      toggle={() => setQuestionMarkTooltipOpened(!questionMarkTooltipOpened)}
                    >
                      Скопируйте токен для подключения плагина и вставьте его в конфиг плагина
                    </Tooltip>
                  </>
                </div>
              ),
              style: {
                width: 260,
              },
              className: 'text-left',
            },
          },
          {
            value: (school) => (
              <div className={styles.multiRowCell}>
                {school.plugins.map((plugin) => {
                  if (!plugin.is_active) {
                    return (
                      <Badge
                        key={plugin.auth_token}
                        style={{
                          backgroundColor: 'var(--warning-color)',
                        }}
                      >
                        Не подключено
                      </Badge>
                    );
                  }

                  return (
                    <Badge
                      key={plugin.auth_token}
                      style={{
                        backgroundColor: 'var(--success-color)',
                      }}
                    >
                      Подключен
                    </Badge>
                  );
                })}
              </div>
            ),
            className: 'text-left',
            header: {
              title: 'Подключение\nплагина',
              style: {
                width: 140,
              },
              className: 'text-left',
            },
          },
          {
            value: (school) => (
              <div className={styles.multiRowCell}>
                {school.plugins.map((plugin, index) => {
                  if (!plugin.date_joined) return <div>—</div>;

                  const { date, time } = formatDateToDateAndTime(new Date(plugin.date_joined));

                  return (
                    <div key={index}>
                       {date}{' '}{time}
                      <br />
                    </div>
                  );
                })}
              </div>
            ),
            className: 'text-left',
            header: {
              title: 'Дата подклю-\nчения плагина',
              style: {
                width: 170,
              },
              className: 'text-left',
            },
          },
          {
            value: (school) => (
              <div className={styles.multiRowCell}>
                {school.plugins.map((plugin, index) => {
                  if (!plugin.last_login) return <div>—</div>;

                  const { date, time } = formatDateToDateAndTime(new Date(plugin.last_login));

                  return (
                    <div key={index}>
                      {date}{' '}{time}
                      <br />
                    </div>
                  );
                })}
              </div>
            ),

            className: 'text-left',
            header: {
              title: 'Последнее исполь-\nзование плагина',
              style: {
                width: 170,
              },
              className: 'text-left',
            },
          },
          {
            value: (school) => (
              <div className={styles.multiRowCell}>
                {school.plugins.map((plugin, index) => {
                  return (
                    <div key={index}>
                      {plugin.version || '—'}
                      <br />
                    </div>
                  );
                })}
              </div>
            ),
            className: 'text-left',
            header: {
              title: 'Версия\nплагина',
              style: {
                width: 100,
              },
              className: 'text-left',
            },
          },
        ]}
        data={paginatedSchools}
        pagination={{
          totalPages,
          currentPage,
          itemsPerPage,
          onChangePage: (newPage: number) => setCurrentPage(newPage),
          onChangeLimit: (newLimit: number) => setItemsPerPage(newLimit),
        }}
      />
    </section>
  );
}
