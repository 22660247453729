enum ComplexDishStatus {
  planned = 'planned',
  active = 'active',
  draft = 'draft',
}

enum ComplexDishKindByAgeRanges {
  breakfastYoung = 'breakfastYoung',
  breakfastTeen = 'breakfastTeen',
  lunchYoung = 'lunchYoung',
  lunchTeen = 'lunchTeen',
  afternoonSnackYoung = 'afternoonSnackYoung',
  afternoonSnackTeen = 'afternoonSnackTeen',
}

type ConfigValueType = {
  name: string;
  backgroundColor: string;
  color: string;
};

type KindConfigValueType = {
  name: string;
};

const statusConfig: Record<ComplexDishStatus, ConfigValueType> = {
  [ComplexDishStatus.planned]: {
    name: 'Запланирован',
    backgroundColor: '#7B61FF',
    color: '#fff',
  },
  [ComplexDishStatus.active]: {
    name: 'Активно',
    backgroundColor: 'var(--success-color)',
    color: '#fff',
  },
  [ComplexDishStatus.draft]: {
    name: 'Черновик',
    backgroundColor: '#B3B3B3',
    color: '#fff',
  },
};

const complexDishKindByAgeRangesConfig: Record<ComplexDishKindByAgeRanges, KindConfigValueType> = {
  [ComplexDishKindByAgeRanges.breakfastYoung]: {
    name: 'Завтраки 6-10',
  },
  [ComplexDishKindByAgeRanges.breakfastTeen]: {
    name: 'Завтраки 11-18',
  },
  [ComplexDishKindByAgeRanges.lunchYoung]: {
    name: 'Обеды 6-10',
  },
  [ComplexDishKindByAgeRanges.lunchTeen]: {
    name: 'Обеды 11-18',
  },
  [ComplexDishKindByAgeRanges.afternoonSnackYoung]: {
    name: 'Полдники 6-10',
  },
  [ComplexDishKindByAgeRanges.afternoonSnackTeen]: {
    name: 'Полдники 11-18',
  },
};

export {
  statusConfig,
  complexDishKindByAgeRangesConfig,
  ComplexDishStatus,
  ComplexDishKindByAgeRanges,
};
