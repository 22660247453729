import IFlavor from '../IFlavor';

export default class KrasPitFlavor implements IFlavor {
  // Assets
  logoBig = '/apps/krasPit/logoBig.svg';
  logoShort = '/apps/krasPit/logoShort.svg';
  patternBg = '/apps/krasPit/patternBg.svg';
  favicon = '/apps/krasPit/favicon.png';

  // Common
  primaryColor = '#00B495';

  // Buttons
  buttonPrimaryBgColor = '#00B495';
  buttonPrimaryTextColor = '#fff';
  buttonPrimaryHoverBgColor = '#00B495';
  buttonPrimaryLightenBgColor = '#00B495';
  buttonLinkTextColor = '#00B495';

  // Table
  tableInvalidRowBgColor = '#F3FFF6';

  // Tile tabs
  tileTabsInactiveBgColor = '#F3FFF6';
  tileTabsInactiveTextColor = '#000';
  tileTabsHoverBgColor = '#F3FFF6';

  // Sidebar
  sidebarActiveItemBgColor = '#EBF4F3';

  // Controls
  checkboxActiveBgImage = '/apps/krasPit/checkboxActive.svg';

  // Calendar
  calendarSelectedCellBgColor = '#00B495';
  calendarSelectedCellTextColor = '#000';
  calendarRangeColor = '#EBF4F3';

  warningColor = '#ED5556';
  successColor = '#0BB86F';
  secondaryColor = '#545CD8';

  enablePaidNutrition = false;
}
